// @ts-ignore
import yaml from "../../data/how-tagging-works.yaml"
import React from "react"
import { validatePageYamlData } from "../../helpers/prop-types"
import Header from "../../components/sections/header"
import ChildMapper from "../../components/child-mapper"
import { graphql } from "gatsby"
import { toothPasteWithSingleChildLandscapeRenderer } from "../../components/child-mappable/toothpaste-with-single-child-landscape"
import { ChevronIntroduction } from "../../components/sections/chevron-introduction"
import LayoutContainer from "../../components/layout/layout-container"

class HowTaggingWorks extends React.Component {
  render() {
    validatePageYamlData(yaml)

    const images = this.props.data

    return (
      <LayoutContainer>
        <Header {...yaml.header} />
        <ChevronIntroduction data={yaml.introduction} />
        <ChildMapper
          data={yaml.five_steps}
          images={images}
          options={{
            chevronColumnWidth: "is-3",
          }}
          render={toothPasteWithSingleChildLandscapeRenderer}
        />
        <ChildMapper
          data={yaml.closing_points}
          render={toothPasteWithSingleChildLandscapeRenderer}
          options={{
            chevronColumnWidth: "is-2",
          }}
        />
      </LayoutContainer>
    )
  }
}

export default HowTaggingWorks

export const query = graphql`
  query {
    tagging_1: file(relativePath: { eq: "how-tagging-works/tagging_1.png" }) {
      ...unsizedFluidImage
    }
    tagging_2: file(relativePath: { eq: "how-tagging-works/tagging_2.png" }) {
      ...unsizedFluidImage
    }
    tagging_3: file(relativePath: { eq: "how-tagging-works/tagging_3.png" }) {
      ...unsizedFluidImage
    }
    tagging_4: file(relativePath: { eq: "how-tagging-works/tagging_4.png" }) {
      ...unsizedFluidImage
    }
    tagging_5: file(relativePath: { eq: "how-tagging-works/tagging_5.png" }) {
      ...unsizedFluidImage
    }
  }
`

import React from "react"
import Landscape from "../h2nits/landscape"
import Toothpaste from "../sections/toothpaste"
import { extractChevronColumnWidth } from "../helpers/extract-options"
import ResponsiveTitlePlain from "../unitary/responsive-title-plain"

export const toothPasteWithSingleChildLandscapeRenderer = ({
  index,
  data,
  images,
  heightRecorder,
  titleMinHeight,
  bodyMinHeight,
  options,
}) => (
  <ToothPasteWithSingleChildLandscape
    key={index}
    listId={index}
    data={data}
    images={images}
    heightRecorder={heightRecorder}
    titleMinHeight={titleMinHeight}
    options={options}
  />
)

class ToothPasteWithSingleChildLandscape extends React.Component {
  render() {
    const titleChild = <ResponsiveTitlePlain title={this.props.data.title} />
    return (
      <Toothpaste
        heightRecorder={this.props.heightRecorder}
        titleMinHeight={this.props.titleMinHeight}
        titleChild={titleChild}
        chevronColumnWidth={extractChevronColumnWidth(this.props.options)}
      >
        <Landscape
          data={this.props.data.children[0]}
          images={this.props.images}
          imageFirst={true}
        />
      </Toothpaste>
    )
  }
}
